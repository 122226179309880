<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card>
          <template v-slot:heading>
            <h3 class="display-2">Struktur Sistem Kerja</h3>
          </template>

          <v-toolbar flat>
            <div style="max-width:400px">
              <v-select v-model="selectSKPD" :disabled="true" :items="skpd" label="SKPD" item-text="v" item-value="k" hide-details></v-select>
            </div>
            <div style="max-width:400px" class="mx-5">
              <v-text-field v-model="search" hide-details label="Cari" append-icon="mdi-magnify"></v-text-field>
            </div>
            <v-spacer></v-spacer>
            <!-- <v-btn class="green" dark @click="createItem()">Tambah Pokja</v-btn> -->
          </v-toolbar>

          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalKeseluruhan"
            :page.sync="page"
            :items-per-page.sync="rowPerPage"
            :loading="loading"
            :footer-props="{'items-per-page-options':[10,20,30]}"
          >
            <template v-slot:item.nomer="{item, index}">
              {{index+1}}
            </template>
            <template v-slot:item.aksi="{item}">
              <div class="d-flex flex-column">
                <v-btn @click="lihat(item)" x-small class="primary ma-1" dark><v-icon small>mdi-eye</v-icon> Lihat</v-btn>
              </div>
            </template>
            <template v-slot:item.nama_pokja="{item}">
              <b>{{item.nama_pokja}}</b> 
            </template>
            <template v-slot:item.nip_ketua="{item}">
              <br>
              <b>NIP:</b> {{item.data_ketua.nip}}
              <br>
              <b>Nama: </b>{{item.data_ketua.nama}}
              <br>
              <b>Jabatan:</b> {{item.data_ketua.jabatan}}
              <br>
              <br>
              <v-avatar tile size="100"> <v-img :src="item.data_ketua.foto"></v-img></v-avatar>
              <br>
              <br>
            </template>
            <template v-slot:item.jumlah_anggota="{item}">
              <b>{{item.jumlah_anggota != null ? 'Maksimal: '+item.jumlah_anggota+ ' orang' : '-'}}</b><br><br>
              <b><font :color="checkJumlahAnggota(item)">{{'Terisi: '+item.data_anggota.length+ ' orang'}}</font></b>
            </template>
            <template v-slot:item.nip_atasan="{item}">
              <br>
              <b>NIP:</b> {{item.data_atasan_ketua.nip}}
              <br>
              <b>Nama: </b>{{item.data_atasan_ketua.nama}}
              <br>
              <b>Jabatan:</b> {{item.data_atasan_ketua.jabatan}}
              <br>
              <br>
              <v-avatar tile size="100"> <v-img :src="item.data_atasan_ketua.foto"></v-img></v-avatar>
              <br>
              <br>
            </template>
            <template v-slot:item.tanggal_mulai="{item}">
              {{gFD(item.tanggal_mulai)}}
            </template>
            <template v-slot:item.tanggal_selesai="{item}">
              <b><font :color="check(item)">{{gFD(item.tanggal_selesai)}}</font></b>
            </template>
            <template v-slot:item.opd="{item}">
              <br>
              <b>Kode SKPD:</b> {{item.opd}}
              <br>
              <b>SKPD: </b>{{item.satuan_kerja}}
              <br>
              <b>Unit Kerja:</b> {{item.unit_kerja}}
              <br>
              <br>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col>
        <base-material-card icon="mdi-account-group">
          <template v-slot:heading>
            <h3>{{title}}</h3>
          </template>
            <hr class="mt-10 mb-10">
            <div class="ml-3">
              <h3>Pilih Kelompok Kerja:</h3>
              <label>(Silahkan pilih kelompok kerja yang ingin anda lihat/ubah)</label>
            </div>
            <v-row class="ml-3 mt-5">
              <v-col cols="9">
                <v-autocomplete dense :items="listStrukturPokja" :rules="[rules.required]" @change="loadListStrukturPokja" v-model="id_pokja" item-text="nama_pokja" item-value="pokja_id" label="Pilih Kelompok Sistem Kerja" ></v-autocomplete>
              </v-col>
              <v-col cols="3">
              </v-col>
            </v-row>
            <v-row class="ml-3">
              <v-col cols="9">
                <v-autocomplete
                  :items="listPegawaiSKPD"
                  v-model="modelListPegawaiSKPD"
                  label="Tambah Anggota Pegawai"
                  :item-text="getNipNama"
                  multiple
                  chips
                  return-object
                  :disabled="disabled"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="3" class="mt-4">
                <v-btn color="primary" @click="dialogTambahAnggotaPokja = true" :disabled="disabled">Tambah Anggota</v-btn>
              </v-col>
            </v-row>
                <v-row v-if="loadingBtn.dataPegawai" class="mt-5">
                  <v-col v-for="i in [1,2,3,4,5,6]" :key="i">
                    <v-skeleton-loader
                    class="mx-auto"
                    min-width="300"
                    type="card"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
                <div class="ml-3 mt-10" v-if="anggota.length > 0">
                  <h4 class="display-2 mb-1 teal--text text--darken-2" align="center" style="height:auto">
                    <b>{{nama_pokja}}</b>
                  </h4>
                </div>
                <v-row class="mt-10" v-if="anggota.length > 0">
                  <v-col v-for="(peg, i) in anggota" :key="i" align="center">
                    <base-material-card
                      class="v-card-profile"
                      :avatar="peg.foto"
                      style="width:270px; "
                    >
                      <v-card-text class="text-center" height='fit-content'>
                        <h4 class="display-1 mb-1 teal--text text--darken-2" style="height:auto">
                          <b>{{peg.is_ketua == true ? 'Ketua' : 'Anggota'}}</b>
                        </h4>
                        <h4 class="display-1 mb-1 orange--text text--darken-2" style="height:auto">
                          {{peg.nama}}
                        </h4>
                        <h4 class="font-weight-light grey--text mb-2">
                          NIP. {{peg.nip}}
                        </h4>
                        <h4 class="font-weight-light green--text" style="font-size:12px; height:auto">
                          {{peg.jabatan}}
                        </h4>
                        <div class="mt-5" height='fit-content'>
                          <v-chip v-if="peg.is_ketua != true && peg.status != null" small label :class="pokjaStatus(peg)" class="ma-1" dark>{{peg.status}}</v-chip>
                        </div>
                      </v-card-text>
                      <v-card-actions class="d-flex justify-center">
                        <div>
                        <v-btn
                        :color="peg.is_ketua == true ? 'cyan darken-4' : 'amber black--text'"
                        dark
                        rounded
                        class="mr-0"
                        :loading="loadingBtn.upd && loadingEl == i"
                        @click="setAsLeader(peg, i)"
                        :small="true"
                        v-if="disabled == false && peg.is_ketua == false"
                        >
                        <span> Atur Sebagai Ketua Pokja </span>
                        </v-btn>
                        </div>

                        <div>
                        <v-btn
                        color="red"
                        dark
                        rounded
                        class="mr-0"
                        :loading="loadingBtn.del && loadingEl == i"
                        @click="deleteMember(peg, i)"
                        :small="true"
                        v-if="disabled == false && peg.is_ketua != true"
                        >
                        <span> Hapus </span>
                        </v-btn>
                        </div>
                      </v-card-actions>
                    </base-material-card>
                  </v-col>
                </v-row>
                <v-row v-else justify="center">
                  <v-col cols="5" v-if="loadingBtn.dataPegawai == false">
                    <v-alert
                    color="blue-grey"
                    dark
                    dense
                    icon="mdi-account-group"
                    prominent
                  >
                    Tidak ada anggota pegawai
                  </v-alert>
                  </v-col>
                </v-row>
        </base-material-card>
      </v-col>
    </v-row> -->

    <!-- <v-dialog v-model="dialogTambahAnggotaPokja" ref="refTambahAnggotaPokja" persistent>
      <v-card>
        <v-card-text>
          <v-form ref="formLapor">
            <h1 class="display-2 my-2">Tambah Anggota Kelompok Kerja</h1>
            <v-row>
              <v-col>
                <h1 class="display-1 my-1">Kelompok Kerja:</h1>
                <label>
                  {{nama_pokja}}
                  <br>
                </label>
              </v-col>
              <v-col>
                <h1 class="display-1 my-1">NIP Pegawai:</h1>
                <label v-for="(v, i) in modelListPegawaiSKPD" :key="i">
                  {{(i+1)}}. {{v.peg_nip}} - {{v.peg_nama}} ({{v.jabatan}})
                  <br>
                </label>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-menu
                  v-model="fromDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="290px"
                  transition="scale-transition"
                  lazy offset-y full-width>
                  <template v-slot:activator="{ on }">
                    <v-text-field v-show="!showItem" label="Tanggal Mulai" :rules="!showItem == false ? [rules.required] : [rules.allowed]" append-icon="mdi-calendar" outlined dense readonly v-model="fromDateVal" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker
                  locale="in-ID"
                  :min="getStartDate" :max="getEndDate"
                  v-model="fromDateVal"
                  no-title
                  @input="fromDateMenu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="290px"
                  transition="scale-transition"
                  lazy offset-y full-width>
                  <template v-slot:activator="{ on }">
                    <v-text-field v-show="!showItem" label="Tanggal Selesai" :rules="!showItem == false ? [rules.required] : [rules.allowed]" append-icon="mdi-calendar" outlined dense readonly v-model="endDateVal" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker
                  locale="in-ID"
                  :min="getStartDate_" :max="getEndDate_"
                  v-model="endDateVal"
                  no-title
                  @input="endDateMenu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row class="mt-0 mb-5">
              <v-col>
                <h1 class="display-1 my-1">Durasi: {{duration}}</h1>
              </v-col>
            </v-row>

            <v-textarea v-model="keterangan" outlined label="Keterangan Permintaan Kepada Atasan Yang Bersangkutan *" required hint="Wajib Diisi ! Minimal 25 karakter" :rules="[rules.required, rules.min25]"></v-textarea>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="addRow()" :disabled="disabled" :loading="loadingBtn.add">Simpan</v-btn>
          <v-btn @click="dialogTambahAnggotaPokja=false">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <!-- The following below is the form for deleting struktur pokja / sistem kerja -->
    <v-dialog v-model="dialogLihat" :width="role === 'administrator' ? '1200px' : '700px'" persistent>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              <h1 class="display-2 my-2">Kelompok Kerja {{views.nama_pokja}}</h1>
            </v-col>
            <v-col>
              <div class="d-flex flex-row-reverse">
              <v-btn icon color="primary" @click="tutup()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              </div>
            </v-col>
          </v-row>
          <!-- <h1 class="display-2 my-2">Kelompok Kerja {{views.nama_pokja}}</h1> -->
          <v-spacer></v-spacer>
          <div class="mt-5">
            <label class="display-1">Pokja: {{views.nama_pokja}}</label><br>
            <label class="display-1">Unit Kerja: {{views.unit_kerja}}</label><br>
            <label class="display-1">Satuan Kerja: {{views.satuan_kerja}}</label><br>
            <label class="display-1" v-if="role === 'administrator'"><b><font color="error">Maksimum Anggota: {{views.jumlah_anggota == null ? 'Silahkan Isi Dahulu Jumlah Anggota' : views.jumlah_anggota+' Orang'}}</font></b></label>
          </div>
          <v-row>
            <v-col></v-col>
            <v-col v-if="role == 'administrator'">
                <div>
                  <v-text-field v-model="searchAnggota" hide-details label="Cari" append-icon="mdi-magnify"></v-text-field>
                </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div v-if="loading">
                <v-row class="mt-5">
                  <v-col>
                    <v-skeleton-loader
                    class="mx-auto"
                    min-width="200"
                    type="table"
                    height="auto"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
              </div>
              <v-simple-table>
                <thead class="green lighten-3">
                  <tr>
                    <th>No</th>
                    <th class="text-center" colspan="2" :width="role === 'administrator' ? '50%' : 'auto'">Anggota</th>
                    <th class="text-center">Jabatan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(peg,i) in views.data_anggota" :key="i" :class="peg.is_ketua == true ? 'green lighten-3' : 'blue lighten-4'">
                    <td>{{ incrementIndex(i) }}</td>
                    <td colspan="2">
                      <div class="ma-3 justify-center align-center text-center">
                        <v-avatar class="rounded-circle" tile size="120"> <v-img :src="peg.foto"></v-img></v-avatar>
                        <br>
                        <b><p style="font-size:14px;margin-bottom:0px !important">{{peg.nip}}</p></b>
                        <b><p style="font-size:14px">{{peg.nama}}</p></b>
                      </div>
                    </td>
                    <td>
                      <b><p style="font-size:14px">{{peg.jabatan}}</p></b>
                      <v-chip small label :class="pokjaStatus(peg)" class="ma-1" dark>{{peg.status}}</v-chip>
                      <br>
                      <br>
                      <v-row v-if="!peg.is_ketua && !disabled">
                        <v-col>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" :loading="loadingBtn.del && loadingEl == i" @click="hapusAnggota(peg, i)" small class="error ma-1" dark><v-icon small>mdi-delete</v-icon></v-btn>
                            </template>
                            <span>Hapus Anggota</span>
                          </v-tooltip>
                        </v-col>
                        <v-col>
                          <!-- <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" :loading="loadingBtn.upd && loadingEl == i" @click="setAsLeader(peg, i)" small class="ma-1" color="yellow darken-2" dark><v-icon small>mdi-medal</v-icon></v-btn>
                            </template>
                            <span>Atur Sebagai Ketua</span>
                          </v-tooltip> -->
                        </v-col>
                      </v-row>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col v-if="role == 'administrator'">
              <div v-if="loadingListAnggota">
                <v-row class="mt-5">
                  <v-col>
                    <v-skeleton-loader
                    class="mx-auto"
                    min-width="200"
                    type="table"
                    height="auto"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
              </div>
              <v-data-table
                :headers="headersListAnggota"
                :items="listPegawaiSKPDJabatan"
                :search="searchAnggota"
                class="orange lighten-4">
                <template v-slot:item.nomer="{ item, index }">
                {{ index+1 }}
                </template>
                <template v-slot:item.peg_nip="{item}">
                  <!-- <b><p style="font-size:14px">{{item.peg_nip}}-{{item.peg_nama}}</p></b> -->
                  <b><p style="font-size:14px">{{item.peg_nip}}</p></b>
                </template>
                <template v-slot:item.peg_nama="{item}">
                  <b><p style="font-size:14px">{{item.peg_nama}}</p></b>
                </template>
                <template v-slot:item.jabatan="{item}">
                  <b><p style="font-size:14px">{{item.jabatan}}</p></b>
                </template>
                <template v-slot:item.aksi="{item}">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" :disabled="disabled" v-on="on" @click="tambahkanAnggota(item)" small class="primary ma-1" dark><v-icon small>mdi-account-plus</v-icon></v-btn>
                    </template>
                    <span>Tambahkan Anggota</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn @click="tutup()">Tutup</v-btn> -->
          <v-btn @click="dialogLihat = false">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTambahAnggotaPokja" ref="refTambahAnggotaPokja" persistent>
      <v-card>
        <v-card-text>
          <v-form ref="formTambah">
            <h1 class="display-2 my-2">Tambah Anggota Kelompok Kerja</h1>
            <v-row>
              <v-col>
                <h1 class="display-1 my-1"><b>Kelompok Kerja:</b></h1>
                <label class="display-1">{{formTambah.nama_pokja}}</label><br>
                <h1 class="display-1 my-1"><b>Unit Kerja:</b></h1>
                <label class="display-1">{{formTambah.unit_kerja}}</label><br>
                <h1 class="display-1 my-1"><b>Satuan Kerja:</b></h1>
                <label class="display-1">{{formTambah.satuan_kerja}}</label><br>
              </v-col>
              <v-col>
                <h1 class="display-1 my-1"><b>Pegawai:</b></h1>
                <label class="display-1"> {{formTambah.peg_nip}} - {{formTambah.peg_nama}} </label><br>
                <label class="display-1"> ({{formTambah.jabatan}})</label><br><br>
                <v-row>
                  <v-col>
                    <h1 class="display-1 my-1"><b>Tanggal Mulai:</b></h1>
                    <label class="display-1"> {{formTambah.tanggal_mulai}}</label><br>
                  </v-col>
                  <v-col>
                    <h1 class="display-1 my-1"><b>Tanggal Selesai:</b></h1>
                    <label class="display-1"> {{formTambah.tanggal_selesai}}</label><br>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-textarea class="mt-3" noResize v-model="keterangan" outlined label="Keterangan Permintaan Kepada Atasan Yang Bersangkutan *" required hint="Wajib Diisi ! Minimal 25 karakter" :rules="[rules.required, rules.min25]"></v-textarea>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="simpanAnggota(formTambah, false)" :disabled="disabled" :loading="loadingBtn.add">Simpan</v-btn>
          <v-btn @click="dialogTambahAnggotaPokja=false">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import _ from 'lodash'
import store from '@/store'
import SistemKerjaService from '@/services/SistemKerjaService'
import SKPService from '@/services/SKPService'
import {defaultRules} from '@/utils/lib'
import _g from '../../global'
// import FormIsiEkspektasiPerilaku from './FormIsiEkspektasiPerilaku.vue'
import {local} from '@/store/local'
import { mapState } from 'vuex'
import _ from 'lodash'
import axios from 'axios'
import OtherService from '@/services/OtherService'

export default {
  components:{
    // FormIsiEkspektasiPerilaku
  },

  data(){
    return{
      valid:true,
      title:'Sistem Kerja',
      forceUpdate:false,
      formDetail:{},
      formValidasi:{},
      formTolakKeterangan:"",
      idTolak:null,
      loading:false,
      detailData:{},
      triwulan:1,
      hides:[],
      selectedItem:{},
      tabItems:['Triwulan I', 'Triwulan II', 'Triwulan III', 'Triwulan IV', 'Tahunan'],
      tableValues:{},
      loadingBtn:{
        add:false,
        del:false,
        upd:false,
        dataPegawai:false
      },
      ratingList:['kurang', 'kurang berhasil', 'cukup', 'berhasil', 'sangat berhasil'],
      rules:{
        ...defaultRules,
        min30: v=> (v && v.length > 30) || 'Minimal 30 karakter'
      },
      dialogTambahAnggotaPokja: false,
      dataPegawaiBawahan: null,
      dataEkspektasiBawahan:{},
      // dataRespon:{},
      isUmpanBalik: false,
      loadingEl:-1,
      loadingElUmpanBalik:-1,
      dataPegawaiBaru: [],
      listPegawaiSKPD: [],
      listPegawaiSKPDJabatan: [],
      modelListPegawaiSKPD: [],
      avatarImages:{},
      listSKPD: [
        {value: "4.03.01", text: "Badan Kepegawaian dan Pengembangan Sumber Daya Manusia"},
        {value: "4.05.06", text: "Badan Kesatuan Bangsa dan Politik"},
        {value: "4.02.01", text: "Badan Keuangan dan Aset Daerah"},
        {value: "4.02.03", text: "Badan Pendapatan Daerah"},
        {value: "4.01.01", text: "Badan Perencanaan Pembangunan, Penelitian dan Pengembangan"},
        {value: "2.17.01", text: "Dinas Arsip dan Perpustakaan"},
        {value: "1.07.01", text: "Dinas Cipta Karya, Bina Konstruksi dan Tata Ruang"},
        {value: "1.05.02", text: "Dinas Kebakaran dan Penanggulangan Bencana"},
        {value: "2.16.01", text: "Dinas Kebudayaan dan Pariwisata"},
        {value: "2.06.01", text: "Dinas Kependudukan dan Pencatatan Sipil"},
        {value: "1.02.01", text: "Dinas Kesehatan"},
        {value: "2.03.01", text: "Dinas Ketahanan Pangan dan Pertanian"},
        {value: "2.01.01", text: "Dinas Ketenagakerjaan"},
        {value: "2.10.01", text: "Dinas Komunikasi dan Informatika"},
        {value: "2.11.01", text: "Dinas Koperasi dan Usaha Kecil dan Menengah"},
        {value: "2.18.01", text: "Dinas Lingkungan Hidup"},
        {value: "2.08.01", text: "Dinas Pemberdayaan Perempuan dan Perlindungan Anak"},
        {value: "2.13.01", text: "Dinas Pemuda dan Olahraga"},
        {value: "2.12.01", text: "Dinas Penanaman Modal dan Pelayanan Terpadu Satu Pintu"},
        {value: "1.01.01", text: "Dinas Pendidikan"},
        {value: "2.02.01", text: "Dinas Pengendalian Penduduk dan Keluarga Berencana"},
        {value: "3.06.01", text: "Dinas Perdagangan dan Perindustrian"},
        {value: "2.09.01", text: "Dinas Perhubungan"},
        {value: "1.08.01", text: "Dinas Perumahan dan Kawasan Permukiman"},
        {value: "1.06.01", text: "Dinas Sosial"},
        {value: "1.03.03", text: "Dinas Sumber Daya Air dan Bina Marga"},
        {value: "4.05.05", text: "Inspektorat Daerah"},
        {value: "4.05.11", text: "Kecamatan Andir"},
        {value: "4.05.29", text: "Kecamatan Antapani"},
        {value: "4.05.27", text: "Kecamatan Arcamanik"},
        {value: "4.05.17", text: "Kecamatan Astana Anyar"},
        {value: "4.05.20", text: "Kecamatan Babakan Ciparay"},
        {value: "4.05.32", text: "Kecamatan Bandung Kidul"},
        {value: "4.05.21", text: "Kecamatan Bandung Kulon"},
        {value: "4.05.13", text: "Kecamatan Bandung Wetan"},
        {value: "4.05.24", text: "Kecamatan Batununggal"},
        {value: "4.05.18", text: "Kecamatan Bojongloa Kaler"},
        {value: "4.05.19", text: "Kecamatan Bojongloa Kidul"},
        {value: "4.05.31", text: "Kecamatan Buahbatu"},
        {value: "4.05.16", text: "Kecamatan Cibeunying Kaler"},
        {value: "4.05.15", text: "Kecamatan Cibeunying Kidul"},
        {value: "4.05.28", text: "Kecamatan Cibiru"},
        {value: "4.05.10", text: "Kecamatan Cicendo"},
        {value: "4.05.08", text: "Kecamatan Cidadap"},
        {value: "4.05.35", text: "Kecamatan Cinambo"},
        {value: "4.05.12", text: "Kecamatan Coblong"},
        {value: "4.05.33", text: "Kecamatan Gedebage"},
        {value: "4.05.26", text: "Kecamatan Kiaracondong"},
        {value: "4.05.23", text: "Kecamatan Lengkong"},
        {value: "4.05.36", text: "Kecamatan Mandalajati"},
        {value: "4.05.34", text: "Kecamatan Panyileukan"},
        {value: "4.05.30", text: "Kecamatan Rancasari"},
        {value: "4.05.22", text: "Kecamatan Regol"},
        {value: "4.05.09", text: "Kecamatan Sukajadi"},
        {value: "4.05.07", text: "Kecamatan Sukasari"},
        {value: "4.05.14", text: "Kecamatan Sumur Bandung"},
        {value: "4.05.25", text: "Kecamatan Ujungberung"},
        {value: "1.02.04", text: "Rumah Sakit Khusus Gigi dan Mulut"},
        {value: "1.02.03", text: "Rumah Sakit Khusus Ibu dan Anak"},
        {value: "1.02.02", text: "Rumah Sakit Umum Daerah (lama)"},
        {value: "1.02.05", text: "Rumah Sakit Umum Daerah"},
        {value: "1.05.01", text: "Satuan Polisi Pamong Praja"},
        {value: "4.05.02", text: "Sekretariat Daerah"},
        {value: "4.05.04", text: "Sekretariat Dewan Perwakilan Rakyat Daerah"}
      ],
      kode_skpd: null,
      listStrukturPokja: [],
      pegawai: [],
      id_pokja: null,
      anggota: [],
      nama_pokja: '',
      satuan_kerja: '',
      unit_kerja: '',
      nip_ketua: '',
      disabled: true,
      fromDateMenu: false,
      endDateMenu: false,
      fromDateVal: null,
      endDateVal: null,
      showItem:false,
      formTambah:{},
      formEdit:{},
      date: new Date(),
      role: '',
      headers:[
        {text:'No', value:'nomer', sortable:false, class:'header-index-profile'},
        {text:'Nama Pokja', value:'nama_pokja', sortable:false, class:'header-index-profile'},
        {text:'Ketua', value:'nip_ketua', sortable:false, class:'header-index-profile'},
        {text:'Atasan', value:'nip_atasan', sortable:false, class:'header-index-profile'},
        {text:'Jumlah Anggota', value:'jumlah_anggota', sortable:false, class:'header-index-profile'},
        {text:'Tanggal Mulai', value:'tanggal_mulai', sortable:false, class:'header-index-profile'},
        {text:'Tanggal Selesai', value:'tanggal_selesai', sortable:false, class:'header-index-profile'},
        {text:'SKPD', value:'opd', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile'},
      ],
      items:[],
      views:[],
      totalKeseluruhan:0,
      selectSKPD:'',
      rowPerPage:0,
      page:1,
      skpd:[],
      search:'',
      pokja_id:null,
      satuan_kerja_id:null,
      unit_kerja_id:null,
      unit_kerja_parent:null,
      dialogLihat:false,
      searchAnggota: '',
      headersListAnggota:[
        {text:'No', value:'nomer', sortable:false, class:'header-index-profile'},
        {text:'Nip', value:'peg_nip', sortable:false, class:'header-index-profile'},
        {text:'Nama', value:'peg_nama', sortable:false, class:'header-index-profile'},
        {text:'Jabatan', value:'jabatan', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile'},
      ],
      loadingListAnggota:false,
    }
  },
  created(){
    this.year = new Date().getFullYear()-1
    this.triwulan = _g.getTriwulan().toString()
    var kode_skpd = this.$store.getters["user/data"].data_asn["kolok"]
    var base_url = window.location.origin
    // var role = this.current.role
    // var username = this.current.username
    let current = store.state.user.current
    let user = store.state.user
    this.role = current.role
    // if(kode_skpd != '4.03.01'){
    //   store.commit('snackbar/setSnack',{message:'Maaf, anda tidak bisa mengakses halaman Sistem Kerja.', color:'warning'})
    //   let confirmAction = confirm("Maaf, anda tidak bisa mengakses halaman Sistem Kerja.");
    //   if (confirmAction) {
    //     window.location.href = base_url + '/#/dashboard'
    //   } else {
    //     window.location.href = base_url + '/#/dashboard'
    //   }
    // }
    let val = {itemsPerPage:10, page: 1}
    this.getListSKPD()
    this.selectSKPD = kode_skpd
    this.updateTable(val)
  },

  computed:{
    ...mapState({
      username: state=> state.user.current.username,
      user: state=> state.user.dataPegawai,
      current: state=> state.user.current
    }),
    getStartDate() {
      let startDate
      if(this.formEdit.tanggal != undefined){
        let tanggal_ = new Date(this.formEdit.tanggal+'T00:00:00')
        startDate = new Date(tanggal_.getFullYear(), tanggal_.getMonth()+1, 1)
      }else{
        startDate = new Date(this.date.getFullYear(), this.date.getMonth(), 1)
      }
      // return startDate.getFullYear()+'-'+this.addZero(startDate.getMonth())+'-'+this.addZero(startDate.getDate())
      return startDate.getFullYear()+'-'+this.addZero(1)+'-'+this.addZero(startDate.getDate())
    },
    getEndDate() {
      let endDate
      if(this.formEdit.tanggal != undefined){
        let tanggal_ = new Date(this.formEdit.tanggal+'T00:00:00')
        let last_date = new Date(tanggal_.getFullYear(), tanggal_.getMonth() + 1, 0)
        endDate = new Date(tanggal_.getFullYear(), tanggal_.getMonth(), last_date.getDate())
      }else{
        endDate = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0)
      }
      return endDate.getFullYear()+'-'+this.addZero(endDate.getMonth()+1)+'-'+this.addZero(endDate.getDate())
    },
    getStartDate_() {
      let startDate_
      if(this.formEdit.tanggal != undefined){
        let tanggal_ = new Date(this.formEdit.tanggal+'T00:00:00')
        startDate_ = new Date(tanggal_.getFullYear(), tanggal_.getMonth()+1, 1)
      }else{
        startDate_ = new Date(this.date.getFullYear(), this.date.getMonth()+1, 1)
      }
      var d = null
      if(this.fromDateVal != null){
        let date = this.fromDateVal.split("-")
        startDate_ = new Date(date[0], date[1], date[2])
        if(date[1] == '12'){
          d = this.fromDateVal
        }else{
          d = startDate_.getFullYear()+'-'+this.addZero(startDate_.getMonth())+'-'+this.addZero(startDate_.getDate())
        }
      }
      return d
    },
    getEndDate_() {
      let endDate
      if(this.formEdit.tanggal != undefined){
        let tanggal_ = new Date(this.formEdit.tanggal+'T00:00:00')
        let last_date = new Date(tanggal_.getFullYear(), tanggal_.getMonth() + 1, 0)
        endDate = new Date(tanggal_.getFullYear(), tanggal_.getMonth(), last_date.getDate())
      }else{
        endDate = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0)
      }
      return endDate.getFullYear()+'-'+this.addZero(12)+'-'+this.addZero(endDate.getDate())
      // return endDate.getFullYear()+'-'+this.addZero(endDate.getMonth()+1)+'-'+this.addZero(endDate.getDate())
    },
    keterangan: {
      get() { return this.text; },
      set: _.debounce(function(newValue) {
        this.formTambah.keterangan = newValue
      }, 100)
    },
    duration() {
      var durasi = ''
      if(this.fromDateVal && this.endDateVal){
        let startDateTrim = this.fromDateVal.split("-")
        let startDate = new Date(startDateTrim[0], startDateTrim[1], startDateTrim[2])
        let endDateTrim = this.endDateVal.split("-")
        let endDate = new Date(endDateTrim[0], endDateTrim[1], endDateTrim[2])
        // To calculate the time difference of two dates
        // var Difference_In_Time = endDate.getTime() - startDate.getTime();
        // // // To calculate the no. of days between two dates
        // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

        // var Difference_In_Month = this.monthDiff(startDate, endDate)
        // var Difference_In_Month_ = this.getMonthDifference(startDate, endDate)
        // var message = this.calcDate(startDate, endDate)
        // durasi = Difference_In_Month_ + ' month ' + Difference_In_Month + ' bulan ' + Difference_In_Days+' hari'
        // durasi = (Difference_In_Days+1) +' hari'

        // Count only working days between mon-fri
        let count = 0;
        const curDate = new Date(startDate.getTime());
        while (curDate <= endDate) {
            const dayOfWeek = curDate.getDay();
            if(dayOfWeek !== 0 && dayOfWeek !== 6) count++;
            curDate.setDate(curDate.getDate() + 1);
        }
        durasi = count + ' hari kerja'
        // durasi = message
      }else{
        durasi = '-'
      }
      return durasi
    }
  },

  mounted(){
    let kode_skpd = this.kode_skpd ? this.kode_skpd : local.getLocal("kolok")
    this.getDataPegawaiSKPD(kode_skpd)
    this.getDataStrukturPokja(null)
    this.$watch(vm => [vm.rowPerPage, vm.search, vm.selectSKPD], val => {
      this.page = 1
      this.updateTable({itemsPerPage:this.rowPerPage, skpd:this.selectSKPD, search:this.search, page:this.page})
    })
  },

  methods:{

    incrementIndex(key) {
        return key + 1;
    },

    getListSKPD(){
      var url = "v1/get-data-skpd2"
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_).then(response => {
        let data = []
        _.each(response.data.data, function(value, key, obj){
          data.push({k:key, v: value})
        })
        this.skpd = data
      }).catch(err => {
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    updateTable(val){
      this.tableValue = val? val:this.tableValue
      console.log(val)
      if(this.tableValue){
        this.loading=true
        var url = "v1/get-struktur-pokja-admin"
        let current = store.state.user.current
        let user = store.state.user
        if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
					current.username = _g.overrideWithPLT(user.current.role)
        }else{
					current.username = user.current.username
        }
        var base_url_api = _g.getBaseURLAPIERK(current.year)
        base_url_api = '/api/';
        const url_ = base_url_api + url
        const config = {
            headers:{
              "x-access-token": user.data.token,
              "Content-Type": "application/json"
            }
        }
        axios.post(url_, {nip: current.username, api: 1, page:this.tableValue.page, perpage: this.tableValue.rowPerPage, row_per_page: this.tableValue.rowPerPage, opd: this.selectSKPD, search: this.search, role:user.current.role},config).then(response => {
					let res = response.data
          var views_ = []
					if (res.success){
						// this.items = response.data.data
            //filter by user / nip atasan yang masuk di list pokja
            this.items = []
            response.data.data.forEach(element => {
              if(current.username == element.nip_atasan){
                this.items.push(element)
              }
            });
            if(this.role != 'superadmin'){
              var temp = []
              response.data.data.forEach(value => {
                var nip_atasan = value.nip_atasan
                value.data_anggota.forEach(anggota => {
                  if(anggota.nip === current.username){
                    temp.push(value)
                  }
                });
                if(nip_atasan === current.username){
                  temp.push(value)
                }
              });
              this.items = temp
            }
            var nip_atasan = null
            if(response.data.data != undefined){
              response.data.data.forEach(value => {
                if(this.pokja_id === parseInt(value.pokja_id)){
                  views_ = value
                  nip_atasan = value.nip_atasan
                }
              });
            }
            this.views = views_
            if(nip_atasan == current.username){
              this.disabled = false
            }else{
              this.disabled = true
            }
            this.totalKeseluruhan = response.data.total_keseluruhan
            console.log(this.items)
						store.commit('snackbar/setSnack',{message: res.message, color:'success'})
					} else {
						store.commit('snackbar/setSnack',{message: res.message, color:res.response})
					}
					this.loading=false
        }).catch(err => {
					var error = err.message
					store.commit('snackbar/setSnack',{message: error, color:'error'})
        })
      }
      // this.loadingBtn.dataPegawai = true
      // SistemKerjaService.getDataValidasiSKPTriwulan(this.triwulan).then(response =>{
      //   // this.responseData = response.data
      //   this.pegawai=response.data.data
      //   this.loadingBtn.dataPegawai = false
      // })

      // SistemKerjaService.getSKP({
      //   search:'',
      //   row_per_page:1,
      //   triwulan:"tahun",
      //   page:5})
      // .then((response)=>{
      //   // console.log('tahunan',response.data)
      //   this.itemsTahunan = response.data
      //   this.dataPegawaiEkspektasiPerilaku = response.data.data[0]
      //   if(this.tahun >= 2022){
      //     this.dataPegawaiEkspektasiPerilaku['ekspektasi_pimpinan'] = response.data.ekspektasi_pimpinan != undefined ? response.data.ekspektasi_pimpinan[0] : []
      //   }
      //   this.eselon = response.data.eselon
      //   this.jft = response.data.jft
      //   console.log(this.itemsTahunan)
      // }).finally(()=>{
      //   this.loadingTahunan = false
      // })
    },

    closeDialog(){
      this.formValidasi = {}
      this.$refs.form.resetValidation()
      this.dialogValidasi=false
    },

    openDialogEkspektasiPerilaku(peg, idx, isUmpanBalik){
      this.loadingEl = idx
      this.isUmpanBalik = isUmpanBalik
      this.dataEkspektasiBawahan.e_berorientasi_pelayanan = ''
      this.dataEkspektasiBawahan.e_akuntabel = ''
      this.dataEkspektasiBawahan.e_kompeten = ''
      this.dataEkspektasiBawahan.e_harmonis = ''
      this.dataEkspektasiBawahan.e_loyal = ''
      this.dataEkspektasiBawahan.e_adaptif = ''
      this.dataEkspektasiBawahan.e_kolaboratif = ''
      this.dataEkspektasiBawahan.r_berorientasi_pelayanan = ''
      this.dataEkspektasiBawahan.r_akuntabel = ''
      this.dataEkspektasiBawahan.r_kompeten = ''
      this.dataEkspektasiBawahan.r_harmonis = ''
      this.dataEkspektasiBawahan.r_loyal = ''
      this.dataEkspektasiBawahan.r_adaptif = ''
      this.dataEkspektasiBawahan.r_kolaboratif = ''
      this.dataEkspektasiBawahan.tahun = this.$store.getters["user/data"].tahun
      var current = store.state.user.data
      var nip = peg.peg_nip ? peg.peg_nip : peg.nip
      SistemKerjaService.getEkspektasiPerilakuKerja(nip, this.triwulan, this.dataEkspektasiBawahan.tahun).then(response=>{
        let res =response.data.data
        res.forEach(el => {
          if(el.nip_atasan == current.username){
            this.dataEkspektasiBawahan = el
          }
        });
        // if(res.data[0] != undefined){
        //   this.dataEkspektasiBawahan = res.data[0]
        // }
        this.dataEkspektasiBawahan.foto = peg.foto
        this.dataEkspektasiBawahan.nip = peg.nip
        this.dataEkspektasiBawahan.jabatan = peg.jabatan
        this.dataEkspektasiBawahan.nama = peg.nama
        this.dataEkspektasiBawahan.jenis = this.triwulan
      }).finally(()=>{
        // this.dataPegawaiBawahan = peg
        if(isUmpanBalik){
          this.loadingElUmpanBalik = -1
          // this.$refs.formUmpanBalikEkspektasiPerilaku.scrollIntoView({ block: 'end', scrollBehavior: 'smooth' });
        }else{
          this.loadingEl = -1
          // this.$refs.formIsiEkspektasiPerilaku.scrollIntoView({ block: 'top', scrollBehavior: 'smooth' });
          // this.$refs.formIsiEkspektasiPerilaku.scrollIntoView({ block: 'start', scrollBehavior: 'smooth' });
        }
        // this.$nextTick(() => {
          // block: 'end' will scroll to top of element instead of bottom
          // this.$refs.formUmpanBalikEkspektasiPerilaku.$el.scrollIntoView({ block: 'end', scrollBehavior: 'smooth' });
        // });
      })
    },

    getImg(nip, peg_foto){
      let avatarImgUrl = "https://simpeg.bandung.go.id/uploads/" + peg_foto
      this.avatarImages[nip] = avatarImgUrl
      return avatarImgUrl
    },

    getDataStrukturPokja(id_pokja){
      let username = this.$store.getters["user/data"].username
      this.loadingBtn.dataPegawai = true
      SistemKerjaService.getStrukturPokja(id_pokja).then(response=>{
        let data = response.data.data
        this.pegawai = data
        let nip_atasan = this.nip_ketua = null
        this.id_pokja = id_pokja
        if(id_pokja == null){
          this.anggota = []
          this.nama_pokja = []
        }else{
          data.forEach(el => {
            if(el.pokja_id == id_pokja){
              nip_atasan = el.nip_atasan
              this.nip_ketua = el.nip_ketua
              this.anggota = el.data_anggota
              this.nama_pokja = el.nama_pokja
            }
          });
        }
        if(this.nip_ketua == username || nip_atasan == username){
          this.disabled = false
        }else{
          this.disabled = true
        }
        this.listStrukturPokja = data
      }).finally(()=>{
        this.loadingBtn.dataPegawai = false
      })
    },

    getNipNama(item) {
      return `${item.peg_nip} - ${item.peg_nama}`;
      // return `${item.nip} - ${item.nama}`;
    },

    addRow(){
      let id_pokja = this.id_pokja
      this.no = this.dataPegawaiBaru.length
      this.modelListPegawaiSKPD.forEach(element => {
        this.no += 1
        var my_object = {
          foto: element.foto,
          nama: element.peg_nama,
          nip: element.peg_nip,
          jabatan: element.jabatan,
          no:this.no,
          is_ketua:false,
          keterangan: this.formTambah.keterangan,
          tanggal_mulai: this.fromDateVal,
          tanggal_selesai: this.endDateVal
        }
        this.dataPegawaiBaru.push(my_object)
        this.formTambah.tanggal_mulai = this.fromDateVal
        this.formTambah.tanggal_selesai = this.endDateVal
        this.formTambah.id_pokja = id_pokja
        this.loadingBtn.add = true

        var current = this.$store.getters["user/current"]
        var base_url_api = _g.getBaseURLAPIERK(current.year)
        const url_add_struktur_pokja = base_url_api + 'v1/add-struktur-pokja'
        axios.post(url_add_struktur_pokja, {
          nip: element.peg_nip,
          pokja_id: this.formTambah.id_pokja == null ? "-" : this.formTambah.id_pokja,
          tanggal_mulai: this.formTambah.tanggal_mulai,
          tanggal_selesai: this.formTambah.tanggal_selesai,
          keterangan: this.formTambah.keterangan
        }).then(response => {
          let res = response.data
          if (res.success){
            store.commit('snackbar/setSnack',{message: res.message, color:'success'})
            this.dataPegawaiBaru.push(my_object)
          } else {
            store.commit('snackbar/setSnack',{message: res.message, color:res.response})
          }
        }).finally(()=>{
          this.loadingBtn.add = false
          this.dialogTambahAnggotaPokja = false
          this.getDataStrukturPokja(this.formTambah.id_pokja)
        }).catch(err => {
          var error = err.response.data.message
          this.loadingBtn.add = false
          store.commit('snackbar/setSnack',{message: error, color:'error'})
        })
        // SistemKerjaService.addStrukturPokja(element.peg_nip, this.formTambah).then(response => {
        //   let res = response.data
        //   if (res.success){
        //     store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        //     this.dataPegawaiBaru.push(my_object)
        //   } else {
        //     store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        //   }
        // }).finally(()=>{
        //   this.loadingBtn.add = false
        // })
      });
      let array = []
      array = this.dataPegawaiBaru.concat(this.anggota)
      this.anggota = array
      this.loadListStrukturPokja()
    },

    setAsLeader(item, idx){
      this.loadingEl = idx
      this.loadingBtn.upd = true
      SistemKerjaService.updStrukturPokja(item.nip, this.pokja_id).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
          this.getDataPegawaiSKPD(this.satuan_kerja_id)
          this.updateTable()
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.loadingEl = -1
        this.loadingBtn.upd = false
      })

      SistemKerjaService.setAsRoleKetua(item.nip, this.pokja_id).then(response => {
        let res = response.data
        if(res.status){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
          this.getDataPegawaiSKPD(this.satuan_kerja_id)
          this.updateTable()
        } else {
          store.commit('snackbar/setSnack',{message: 'Terjadi kesalahan saat menyimpan peran ketua pokja: '+res.message, color:res.response})
        }
      }).finally(()=>{
        this.loadingEl = -1
        this.loadingBtn.upd = false
      })
    },

    deleteMember(item, idx) {
      this.loadingEl = idx
      this.loadingBtn.del = true
      SistemKerjaService.delStrukturPokja(item.nip, this.id_pokja).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
          for (let i = 0; i < this.anggota.length; i++) {
            const el = this.anggota[i];
            if(el.nip == item.nip){
              this.anggota.splice(i, 1)
            }
          }
          // re-indexing
          for (let i = 0; i < this.anggota.length; i++) {
            this.anggota[i].no = i+1
          }
          console.log(this.anggota)
          this.loadListStrukturPokja()
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.loadingEl = -1
        this.loadingBtn.del = false
      })
    },

    loadListStrukturPokja(){
      let id = this.id_pokja
      this.getDataStrukturPokja(id)
      // let kode_skpd = this.kode_skpd ? this.kode_skpd : local.getLocal("kolok")
      this.getDataPegawaiSKPD()
    },

    // loadListPegawaiSKPD(){
    //   this.getDataPegawaiSKPD(this.kode_skpd)
    // },

    getDataPegawaiSKPD(satuan_kerja_id){
      // let kode_skpd = local.getLocal("kolok") ? local.getLocal("kolok") : this.$store.getters["user/data"].data_asn.kolok
      var kode_skpd = local.getLocal("kolok")
      if(kode_skpd == null){
        kode_skpd = this.$store.getters["user/data"].data_asn["kolok"]
      }
      this.listPegawaiSKPD = []
      this.listPegawaiSKPDJabatan = []
      // this.modelListPegawaiSKPD = []
      // SKPService.getPegawaiSKPD(kode_skpd).then(response=>{
      //   response.data.data.forEach(element => {
      //     element.foto = this.getImg(element.nip, element.peg_foto)
      //   });
      //   this.listPegawaiSKPD = response.data.data
      // })
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }

      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_get_data = base_url_api + 'v1/get-pegawai-skpd-req-post'
      axios.post(url_get_data, {
        nip: current.username, kode_skpd: kode_skpd
      }).then(response => {
        response.data.data.forEach(element => {
          element.foto = this.getImg(element.nip, element.peg_foto)
        });
        this.listPegawaiSKPD = response.data.data

        let dataPegawai = []
        var id_pokja = this.pokja_id
        var satuan_kerja_id = this.satuan_kerja_id
        _.each(response.data.data, function(value){
          dataPegawai.push({
            pokja_id:id_pokja,
            satuan_kerja_id: satuan_kerja_id,
            ...value
          })
        })
        this.listPegawaiSKPDJabatan = dataPegawai
      }).catch(err => {
        var error = err.message ? err.message : err.response.data.message
        this.loadingEl = -1
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },

    monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },

    getMonthDifference(startDate, endDate) {
      return (
        endDate.getMonth() -
        startDate.getMonth() +
        12 * (endDate.getFullYear() - startDate.getFullYear())
      );
    },

    calcDate(date1,date2) {
      var diff = Math.floor(date1.getTime() - date2.getTime());
      var day = 1000 * 60 * 60 * 24;

      var days = Math.floor(diff/day);
      var months = Math.floor(days/31);
      var years = Math.floor(months/12);

      // var message = date2.toDateString();
      // message += " was "
      // message += days + " days " 
      // message += months + " months "
      // message += years + " years ago \n"
      // message += " was "
      // message += months + " bulan "
      var message = days + " hari " 

      return message
    },

    pokjaStatus(peg){
      let status = peg.status ? peg.status.toLowerCase() : ''
      return{
        warning:status === 'menunggu persetujuan atasan' || status === '',
        black:status === 'persetujuan ditolak',
        green: status === 'persetujuan diterima',
      }
    },

    gFD(d){
      return _g.getFullDate(d)
    },

    gFDD(d){
      return _g.getFullDateDay(d)
    },

    check(d){
      let startDateTrim = d.tanggal_selesai.split("-")
      let startDate = new Date(parseInt(startDateTrim[0]), (parseInt(startDateTrim[1])-1), parseInt(startDateTrim[2]))
      let dateNow = new Date()
      let count = 0;
      const curDate = new Date(startDate.getTime());
      while (dateNow <= curDate) {
          const dayOfWeek = dateNow.getDay();
          if(dayOfWeek !== 0 && dayOfWeek !== 6) count++;
          dateNow.setDate(dateNow.getDate() + 1);
      }
      let endDueDate = count
      if(endDueDate <= 1){
        return 'error'
      }else if(endDueDate <= 3){
        return 'orange'
      }else{
        return 'green'
      }
    },

    lihat(item){
      // this.formEdit = _.clone(item)
      // this.selectSKPD = item.satuan_kerja_id
      this.pokja_id = null
      this.pokja_id = item.pokja_id
      this.satuan_kerja_id = item.satuan_kerja_id
      this.unit_kerja_id = item.unit_kerja_id
      this.nama_pokja = item.nama_pokja
      this.tanggal_mulai = item.tanggal_mulai
      this.tanggal_selesai = item.tanggal_selesai
      this.satuan_kerja = item.satuan_kerja
      this.unit_kerja = item.unit_kerja
      this.updateTable()
      this.getDataPegawaiSKPD(item.satuan_kerja_id)
      this.dialogLihat = true
    },

    hapusAnggota(item, idx) {
      this.loadingEl = idx
      this.loadingBtn.del = true
      SistemKerjaService.delStrukturPokja(item.nip, this.pokja_id).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
          this.getDataPegawaiSKPD(this.satuan_kerja_id)
          this.updateTable()
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.loadingEl = -1
        this.loadingBtn.del = false
      })
    },

    tambahkanAnggota(v){
      if(v.unit_kerja_parent != null){
        if(this.unit_kerja_id != v.unit_kerja_parent){
          this.formTambah = v
          this.formTambah.tanggal_mulai = this.tanggal_mulai
          this.formTambah.tanggal_selesai = this.tanggal_selesai
          this.formTambah.nama_pokja = this.nama_pokja
          this.formTambah.satuan_kerja = this.satuan_kerja
          this.formTambah.unit_kerja = this.unit_kerja
          this.dialogTambahAnggotaPokja = true
        }else{
          this.simpanAnggota(v, true)
        }
      }else{
        if(this.unit_kerja_id != v.unit_kerja_id){
          this.formTambah = v
          this.formTambah.tanggal_mulai = this.tanggal_mulai
          this.formTambah.tanggal_selesai = this.tanggal_selesai
          this.formTambah.nama_pokja = this.nama_pokja
          this.formTambah.satuan_kerja = this.satuan_kerja
          this.formTambah.unit_kerja = this.unit_kerja
          this.dialogTambahAnggotaPokja = true
        }else{
          this.simpanAnggota(v, true)
        }
      }
    },

    simpanAnggota(v, is_super_admin){
      let current = store.state.user.current
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_add_struktur_pokja = base_url_api + 'v1/add-struktur-pokja'
      axios.post(url_add_struktur_pokja, {
        nip: v.peg_nip,
        pokja_id: v.pokja_id,
        tanggal_mulai: v.tanggal_mulai,
        tanggal_selesai: v.tanggal_selesai,
        keterangan: v.keterangan,
        is_super_admin: is_super_admin
      }).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
          this.getDataPegawaiSKPD(v.satuan_kerja_id)
          this.updateTable()
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.dialogLihat = true
        this.dialogTambahAnggotaPokja = false
      }).catch(err => {
        var error = err.response.data.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    tutup(){
      this.dialogLihat=false
      this.selectSKPD = this.$store.getters["user/data"].data_asn["kolok"]
      let val = {itemsPerPage:10, page: 1}
      this.updateTable(val)
    },

    checkJumlahAnggota(item){
      var v = item.jumlah_anggota - item.data_anggota.length
      if(v <= 1){
        return 'error'
      }else if(v <= (item.jumlah_anggota/2)){
        return 'orange'
      }else{
        return 'green'
      }
    },
  },
}
</script>

<style lang="css" scoped>
</style>
